var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"work content"},[_c('main',[_c('section',{staticClass:"header"},[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("portfolio.title"))+" ")]),_c('div',{staticClass:"grid interior",attrs:{"id":"interior"}},[(_vm.projects)?_c('h3',[_vm._v(" "+_vm._s(_vm.$t("portfolio.interior-title"))+" ")]):_vm._e(),(_vm.projects)?_c('transition-group',{attrs:{"name":"grid-complete"}},[(_vm.getCookie('token'))?_c('AddProject',{key:"add-project",attrs:{"selected":"Interior"}}):_vm._e(),_vm._l((_vm.projects
                            .filter(function (x) { return x.category == 'interior'; })
                            .filter(function (x) { return !_vm.getCookie('token') ? x.visible : true; }
                            )),function(project){return _c('Project',{key:project.id,attrs:{"project":project},on:{"hiding":function($event){_vm.$emit(
                                'loading',
                                _vm.$t('portfolio.hide.hide-loading')
                            )},"showing":function($event){_vm.$emit(
                                'loading',
                                _vm.$t('portfolio.hide.show-loading')
                            )},"deleting":function($event){_vm.$emit('loading', _vm.$t('portfolio.delete.loading'))},"done":function($event){return _vm.$emit('done')}}})})],2):_vm._e()],1),_c('div',{staticClass:"grid exterior",attrs:{"id":"exterior"}},[(_vm.projects)?_c('h3',[_vm._v(" "+_vm._s(_vm.$t("portfolio.exterior-title"))+" ")]):_vm._e(),(_vm.projects)?_c('transition-group',{attrs:{"name":"grid-complete"}},[(_vm.getCookie('token'))?_c('AddProject',{key:"add-project",attrs:{"selected":"Exterior"}}):_vm._e(),_vm._l((_vm.projects
                            .filter(function (x) { return x.category == 'exterior'; })
                            .filter(function (x) { return !_vm.getCookie('token') ? x.visible : true; }
                            )),function(project){return _c('Project',{key:project.id,attrs:{"project":project},on:{"hiding":function($event){_vm.$emit(
                                'loading',
                                _vm.$t('portfolio.hide.hide-loading')
                            )},"showing":function($event){_vm.$emit(
                                'loading',
                                _vm.$t('portfolio.hide.show-loading')
                            )},"deleting":function($event){_vm.$emit('loading', _vm.$t('portfolio.delete.loading'))},"done":function($event){return _vm.$emit('done')}}})})],2):_vm._e()],1),_c('div',{staticClass:"grid plan",attrs:{"id":"floorplan"}},[(_vm.projects)?_c('h3',[_vm._v(" "+_vm._s(_vm.$t("portfolio.floorplan-title"))+" ")]):_vm._e(),(_vm.projects)?_c('transition-group',{attrs:{"name":"grid-complete"}},[(_vm.getCookie('token'))?_c('AddProject',{key:"add-project",attrs:{"selected":"Floorplan"}}):_vm._e(),_vm._l((_vm.projects
                            .filter(function (x) { return x.category == 'floorplan'; })
                            .filter(function (x) { return !_vm.getCookie('token') ? x.visible : true; }
                            )),function(project){return _c('Project',{key:project.id,attrs:{"project":project},on:{"hiding":function($event){_vm.$emit(
                                'loading',
                                _vm.$t('portfolio.hide.hide-loading')
                            )},"showing":function($event){_vm.$emit(
                                'loading',
                                _vm.$t('portfolio.hide.show-loading')
                            )},"deleting":function($event){_vm.$emit('loading', _vm.$t('portfolio.delete.loading'))},"done":function($event){return _vm.$emit('done')}}})})],2):_vm._e()],1)])]),(_vm.$route.name !== '404')?_c('Footer'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }