<template>
    <div class="work content">
        <main>
            <section class="header">
                <h2 class="title">
                    {{ $t("portfolio.title") }}
                </h2>
                <div class="grid interior" id="interior">
                    <h3 v-if="projects">
                        {{ $t("portfolio.interior-title") }}
                    </h3>
                    <transition-group name="grid-complete" v-if="projects">
                        <AddProject
                            v-if="getCookie('token')"
                            key="add-project"
                            selected="Interior"
                        />
                        <Project
                            @hiding="
                                $emit(
                                    'loading',
                                    $t('portfolio.hide.hide-loading')
                                )
                            "
                            @showing="
                                $emit(
                                    'loading',
                                    $t('portfolio.hide.show-loading')
                                )
                            "
                            @deleting="
                                $emit('loading', $t('portfolio.delete.loading'))
                            "
                            @done="$emit('done')"
                            v-for="project in projects
                                .filter(x => x.category == 'interior')
                                .filter(x =>
                                    !getCookie('token') ? x.visible : true
                                )"
                            :key="project.id"
                            :project="project"
                        />
                    </transition-group>
                </div>

                <div class="grid exterior" id="exterior">
                    <h3 v-if="projects">
                        {{ $t("portfolio.exterior-title") }}
                    </h3>
                    <transition-group name="grid-complete" v-if="projects">
                        <AddProject
                            v-if="getCookie('token')"
                            key="add-project"
                            selected="Exterior"
                        />
                        <Project
                            @hiding="
                                $emit(
                                    'loading',
                                    $t('portfolio.hide.hide-loading')
                                )
                            "
                            @showing="
                                $emit(
                                    'loading',
                                    $t('portfolio.hide.show-loading')
                                )
                            "
                            @deleting="
                                $emit('loading', $t('portfolio.delete.loading'))
                            "
                            @done="$emit('done')"
                            v-for="project in projects
                                .filter(x => x.category == 'exterior')
                                .filter(x =>
                                    !getCookie('token') ? x.visible : true
                                )"
                            :key="project.id"
                            :project="project"
                        />
                    </transition-group>
                </div>

                <div class="grid plan" id="floorplan">
                    <h3 v-if="projects">
                        {{ $t("portfolio.floorplan-title") }}
                    </h3>
                    <transition-group name="grid-complete" v-if="projects">
                        <AddProject
                            v-if="getCookie('token')"
                            key="add-project"
                            selected="Floorplan"
                        />
                        <Project
                            @hiding="
                                $emit(
                                    'loading',
                                    $t('portfolio.hide.hide-loading')
                                )
                            "
                            @showing="
                                $emit(
                                    'loading',
                                    $t('portfolio.hide.show-loading')
                                )
                            "
                            @deleting="
                                $emit('loading', $t('portfolio.delete.loading'))
                            "
                            @done="$emit('done')"
                            v-for="project in projects
                                .filter(x => x.category == 'floorplan')
                                .filter(x =>
                                    !getCookie('token') ? x.visible : true
                                )"
                            :key="project.id"
                            :project="project"
                        />
                    </transition-group>
                </div>
            </section>
        </main>
        <Footer v-if="$route.name !== '404'" />
    </div>
</template>

<script>
import Project from "@/components/Project";
import AddProject from "@/components/AddProject";
import Footer from "@/components/layout/Footer";
import { getCookie } from "@/helpers";

export default {
    components: {
        Project,
        Footer,
        AddProject
    },
    data: function() {
        return {
            selected: null
        };
    },
    computed: {
        projects() {
            this.$emit("done");
            return this.$store.getters.allProjects;
        }
    },
    mounted() {
        if (!this.projects) {
            this.$emit("loading");
            this.$store.dispatch("getProjects");
        }
    },
    methods: {
        // toggleTag(tag) {
        //     this.selected = tag;
        // },
        // toggleAll() {
        //     this.selected = null;
        // },
        getCookie: getCookie
    },
    metaInfo() {
        return {
            title: "Portfolio",
            htmlAttrs: {
                lang: this.$root.$i18n.locale
            },
            meta: [
                // Primary Meta Tags
                {
                    name: "title",
                    content: "Portfolio - Renders by Julie"
                },
                {
                    name: "description",
                    content:
                        "Overzicht van een aantal van onze projecten waaronder interieur, exterieur en grondplannen. Bekijk ons portfolio om te zien wat wij jou kunnen bieden!"
                },
                // Secondary Meta Tags
                {
                    name: "author",
                    content: "Julie Lejour"
                },
                {
                    name: "keywords",
                    content:
                        "portfolio, project, projecten, interieur, interior, exterieur, exterior, floorplan, vloerplan, render, renders, rendus, visual, visuals, visualisaties, model, beeld, beelden, 3D, 2D, ruimte, ruimtes, space, spaces sales plans, plans, plannen, verkoopsplannen, verkoopplannen, vloerplannen, grondplannen, plattegronden, floorplans, plans de vente, schema, opmaken, maken, visualiseren, ontwerpen, modelleren, visualize, visueel, 3D foto’s, 3D images, Julie, Lejour, Julie Lejour, Renders, Renders by Julie, rendersbyjulie, rendersbyjulie.be, portfolio, dwg, panorama, architecture, architectuur, Gent, Sint-Martens-Latem, De Pinte, Deinze, Merelbeke, Drongen, Oost-Vlaanderen"
                },
                {
                    name: "robots",
                    content: "index, follow"
                },
                {
                    "http-equiv": "Content-Type",
                    content: "text/html; charset=utf-8"
                },
                {
                    name: "language",
                    content: this.$root.$i18n.locale === "en" ? "EN" : "NL"
                },
                {
                    name: "revisit-after",
                    content: "30 days"
                },
                {
                    name: "publisher",
                    content: "Renders by Julie"
                },
                {
                    name: "copyright",
                    content: `Copyright ${new Date().getFullYear()} Renders by Julie - All Rights Reserved`
                },
                // Open Graph / Facebook
                { property: "og:locale", content: this.$root.$i18n.locale },
                { property: "og:type", content: "website" },
                {
                    property: "og:url",
                    content: "https://rendersbyjulie.be/portfolio"
                },
                {
                    property: "og:title",
                    content: "Portfolio - Renders by Julie"
                },
                {
                    property: "og:description",
                    content:
                        "Overzicht van een aantal van onze projecten waaronder interieur, exterieur en grondplannen. Bekijk ons portfolio om te zien wat wij jou kunnen bieden!"
                },
                {
                    property: "og:image",
                    content: require("@/assets/images/home/Home 2.jpg")
                },
                // Twitter
                { name: "twitter:card", content: "summary_large_image" },
                {
                    name: "twitter:url",
                    content: "https://rendersbyjulie.be/portfolio"
                },
                {
                    name: "twitter:title",
                    content: "Portfolio - Renders by Julie"
                },
                {
                    name: "twitter:description",
                    content:
                        "Overzicht van een aantal van onze projecten waaronder interieur, exterieur en grondplannen. Bekijk ons portfolio om te zien wat wij jou kunnen bieden!"
                },
                {
                    name: "twitter:image",
                    content: require("@/assets/images/home/Home 2.jpg")
                }
            ]
        };
    }
};
</script>

<style lang="scss">
.work {
    main {
        min-height: 100vh;
    }

    .header {
        margin-bottom: 3.5rem;

        .title {
            text-align: right;
            font-family: "Nelphim", serif;
            font-weight: normal;
        }

        @media (max-width: 700px) {
            padding-top: 0;

            .title {
                text-align: left;
            }
        }
    }

    .grid {
        margin-top: 3rem;

        h3 {
            text-transform: capitalize;
            font-size: 36px;
            margin: 0;
            font-family: "Nelphim", serif;
            font-weight: normal;
            letter-spacing: 0;
            line-height: 56px;
        }

        .project {
            transition: all 0.2s ease-out;
        }
        .grid-complete-enter,
        .grid-complete-leave-to {
            opacity: 0;
            transform: translateY(2rem);
        }
        .grid-complete-leave-active {
            display: none;
            opacity: 0;
            transform: translateY(2rem);
        }

        > span {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 1.5rem;

            @media (max-width: 1000px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 600px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}
</style>
