<template>
    <div class="project">
        <div
            class="project-menu"
            v-if="getCookie('token')"
            @click="$modal.show(`modal-menu-${project.id}`)"
        >
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
        </div>
        <router-link
            :to="`/portfolio/${project.id}/${project.key}`"
            class="image-link"
        >
            <div
                :class="{
                    overlay: true,
                    'invisible-project': !project.visible
                }"
            >
                <p class="inner-title">
                    {{ project.title }}
                </p>
                <p v-if="!project.visible">
                    {{ $t("portfolio.hide.hidden") }}
                </p>
            </div>
            <img
                :title="`Project ${project.title}`"
                :src="
                    `${$apiroute}/images/${project.id}-${project.key}/${project.thumbnail}`
                "
                :alt="`Thumbnail for project ${project.title}`"
            />
        </router-link>
        <h4>
            Project {{ project.title }}<span class="hyphen">-</span
            ><span class="location">{{ project.location }}</span>
        </h4>
        <EditModal
            :id="project.id"
            :hidden="!project.visible"
            @edit="editProject"
            @hide="hideProject"
            @delete="deleteProject"
        />
    </div>
</template>

<script>
import axios from "axios";
// import Link from "@/components/Link";
import EditModal from "@/components/EditProjectModal";
import { getCookie } from "@/helpers";

export default {
    components: {
        // Link,
        EditModal
    },
    props: {
        project: Object
    },
    methods: {
        getCookie: getCookie,
        editProject(id) {
            this.$modal.hide(`modal-menu-${id}`);
            this.$router.push({
                name: "EditProject",
                params: {
                    project: this.project
                }
            });
        },
        async hideProject(id) {
            this.$modal.hide(`modal-menu-${id}`);

            // Toggle hide/show
            this.project.visible ? this.$emit("hiding") : this.$emit("showing");
            await axios
                .put(`${this.$apiroute}/projects/toggle/hide/${id}`, null, {
                    headers: {
                        Authorization: `Bearer ${getCookie("token")}`
                    }
                })
                .then(response => {
                    this.$store.dispatch("getProjects");
                })
                .catch(error => {
                    console.log(error);
                });
            this.$emit("done");
        },
        async deleteProject(id) {
            this.$modal.hide(`modal-menu-${id}`);

            // Delete project
            this.$emit("deleting");
            await axios
                .delete(`${this.$apiroute}/projects/delete/${id}`, {
                    headers: {
                        Authorization: `Bearer ${getCookie("token")}`
                    }
                })
                .then(response => {
                    this.$store.dispatch("getProjects");
                })
                .catch(error => {
                    console.log(error);
                });
            this.$emit("done");
        }
    }
};
</script>

<style lang="scss">
.project {
    overflow: hidden;
    position: relative;

    .project-menu {
        z-index: 2;
        width: 3rem;
        height: 3rem;
        background: $washed-red;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        span {
            width: 0.3rem;
            height: 0.3rem;
            display: block;
            background: $black;
            border-radius: 50%;
            transition: margin 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);

            &:nth-of-type(2) {
                margin: 0 0.3rem;
            }
        }

        &:hover {
            span:nth-of-type(2) {
                margin: 0 0.4rem;
            }
        }
    }

    a.image-link {
        display: flex;
        overflow: hidden;
        height: 20rem;
        position: relative;

        .overlay {
            opacity: 0;
            z-index: 1;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transition: opacity 0.2s linear;
            background: rgba(255, 255, 255, 0.7);

            display: grid;
            place-content: center;

            &:hover {
                opacity: 1;
            }

            &.invisible-project {
                opacity: 1;
                p {
                    font-weight: bolder;
                }
            }

            .inner-title {
                font-size: 32px;
                // font-family: "acumin-pro";
                // font-weight: bold;
                // letter-spacing: 0rem;
                margin: 0;
                font-family: "Nelphim", serif;
                font-weight: normal;
                letter-spacing: 0;
            }
        }

        img {
            width: 100%;
            object-fit: cover;
            transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28),
                filter 0.1s cubic-bezier(0.18, 0.89, 0.32, 1.28);
        }

        &:hover {
            // .overlay {
            //     opacity: 1;
            // }

            img {
                transform: scale(1.1);
                filter: blur(1px);
            }
        }

        &:active {
            img {
                transform: scale(1);
                filter: blur(1px);
            }
        }
    }

    h4 {
        white-space: nowrap;
        display: flex;
        align-items: center;
        font-family: "Titillium Web", sans-serif;
        margin: 0.2rem 0 0 0;
        letter-spacing: 0;

        .hyphen {
            padding: 0.5rem;
        }

        * {
            font-weight: normal;
            font-size: 16px;
        }
    }
}
</style>
