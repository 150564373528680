<template>
    <modal
        class="modal-menu"
        :name="`modal-menu-${id}`"
        width="100%"
        height="auto"
        transition="up"
    >
        <ul>
            <li @click="editProject">{{ $t("portfolio.edit.title") }}</li>
            <li @click="hideProject">
                {{
                    hidden
                        ? $t("portfolio.hide.show")
                        : $t("portfolio.hide.hide")
                }}
            </li>
            <li class="red" @click="deleteProject">
                {{ $t("portfolio.delete.title") }}
            </li>
        </ul>
    </modal>
</template>
<script>
export default {
    props: {
        id: { required: true },
        hidden: { required: true, default: false }
    },
    methods: {
        editProject() {
            this.$emit("edit", this.id);
        },
        hideProject() {
            this.$emit("hide", this.id);
        },
        deleteProject() {
            if (confirm(this.$t("portfolio.delete.confirm"))) {
                this.$emit("delete", this.id);
            }
        }
    }
};
</script>

<style lang="scss">
.vm--modal {
    border: none;
    position: absolute !important;
    bottom: 0;
    top: unset !important;
    border-radius: 0px;
    max-width: 100%;
    z-index: 300;

    li {
        padding: 1rem;
        margin: 0 1rem;
        font-family: "Titillium Web", sans-serif;
        font-size: 18px;
        transition: background-color 0.1s linear, padding 0.1s ease-in;
        cursor: pointer;

        & ~ li {
            border-top: 1px solid $grey;
        }

        &:hover {
            background-color: $washed-red;
        }

        &:active {
            background-color: $washed-red;
            padding-left: 1.5rem;
        }
    }
}

.up-enter-active {
    transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.up-leave-active {
    transition: transform 0.1s ease-in;
}

.up-enter /* .fade-leave-active below version 2.1.8 */ {
    transform: translateY(1rem);
}

.up-leave-to {
    transform: translateY(10rem);
}
</style>
