<template>
    <div class="project">
        <router-link
            :to="{ name: 'AddProject', params: { category: selected } }"
            class="image-link"
        >
            <div class="plus-container">
                <i class="icon plus"></i>
            </div>
        </router-link>
        <h4>
            {{ $t("portfolio.add.catch") }}<span class="hyphen">-</span
            ><span class="location">{{ selected }}</span>
        </h4>
    </div>
</template>

<script>
export default {
    props: {
        selected: String
    }
};
</script>

<style lang="scss" scoped>
.project {
    overflow: hidden;

    a.image-link {
        display: flex;
        overflow: hidden;
        height: 20rem;
        position: relative;

        .plus-container {
            position: absolute;
            font-size: 7.2rem;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            display: grid;
            place-content: center;
            background: $washed-red;

            display: grid;
            place-content: center;

            i {
                transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
                height: 5rem;
                width: 5rem;
            }
        }

        img {
            width: 100%;
            object-fit: cover;
            transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28),
                filter 0.1s cubic-bezier(0.18, 0.89, 0.32, 1.28);
        }

        &:hover {
            .icon {
                transform: rotate(90deg) scale(1.2);
            }
        }

        &:active {
            .icon {
                transform: scale(0.9);
            }
        }
    }

    h4 {
        white-space: nowrap;
        display: flex;
        align-items: center;
        font-family: "Titillium Web", sans-serif;
        margin: 0.2rem 0 0 0;
        letter-spacing: 0;

        .hyphen {
            padding: 0.5rem;
        }

        * {
            font-weight: normal;
            font-size: 16px;
        }
    }
}
</style>
